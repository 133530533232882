import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'node-fetch';
import { getCookie } from '../helpers/cookies';

const httpLink = createUploadLink({
    uri: process.env.REACT_APP_GQL_URI,
    fetch,
    credentials: 'same-origin',
});

const authLink = setContext(({operationName}, {headers}) => {
    // const user_token = localStorage.getItem('user_token');
    const user_token = getCookie('nominex') || null;
    if (!user_token) {
        return {
            headers: {
                ...headers,
            },
        };
    } else {
        const uploadHeader = operationName === 'uploadFile' ?
            {'X-Apollo-Operation-Name': operationName} : undefined;
        return {
            headers: {
                ...headers,
                ...uploadHeader,
                Authorization: `Bearer ${user_token}`,
            },
        };
    }

});
const apollo = new ApolloClient({
    connectToDevTools: true,
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: authLink.concat(httpLink)
});

export default apollo;
