export const USER_ROLES = {
    superUsuario: {
        slug: 'SUPER_USUARIO',
        name: 'Super Usuario',
        description: 'Rol de super usuario',
    },
    planeacionDirector: {
        slug: 'PLANEACION_DIRECTOR',
        name: 'Planeación - Director',
        description: 'Rol de director de planeación',
    },
    planeacionGerente: {
        slug: 'PLANEACION_GERENTE',
        name: 'Planeación - Gerente',
        description: 'Rol de gerente de planeación',
    },
    planeacionSupervisor: {
        slug: 'PLANEACION_SUPERVISOR',
        name: 'Planeación - Supervisor',
        description: 'Rol de supervisor de planeación',
    },
    planeacionOperativo: {
        slug: 'PLANEACION_OPERATIVO',
        name: 'Planeación - Operativo',
        description: 'Rol de operativo de planeación',
    },
    implementacionDirector: {
        slug: 'IMPLEMENTACION_DIRECTOR',
        name: 'Implementación - Director',
        description: 'Rol de director de implementación',
    },
    implementacionGerente: {
        slug: 'IMPLEMENTACION_GERENTE',
        name: 'Implementación - Gerente',
        description: 'Rol de gerente de implementación',
    },
    implementacionSupervisor: {
        slug: 'IMPLEMENTACION_SUPERVISOR',
        name: 'Implementación - Supervisor',
        description: 'Rol de supervisor de implementación',
    },
    implementacionOperativo: {
        slug: 'IMPLEMENTACION_OPERATIVO',
        name: 'Implementación - Operativo',
        description: 'Rol de operativo de implementación',
    },
    operacionDirector: {
        slug: 'OPERACION_DIRECTOR',
        name: 'Operación - Director',
        description: 'Rol de director de operación',
    },
    operacionGerente: {
        slug: 'OPERACION_GERENTE',
        name: 'Operación - Gerente',
        description: 'Rol de gerente de operación',
    },
    operacionSupervisor: {
        slug: 'OPERACION_SUPERVISOR',
        name: 'Operación - Supervisor',
        description: 'Rol de supervisor de operación',
        canAssignToClient: true,
    },
    operacionOperativo: {
        slug: 'OPERACION_OPERATIVO',
        name: 'Operación - Operativo',
        description: 'Rol de operativo de operación',
        canAssignToClient: true,
    },
    contabilidadDirector: {
        slug: 'CONTABILIDAD_DIRECTOR',
        name: 'Contabilidad - Director',
        description: 'Rol de director de contabilidad',
    },
    contabilidadGerente: {
        slug: 'CONTABILIDAD_GERENTE',
        name: 'Contabilidad - Gerente',
        description: 'Rol de gerente de contabilidad',
    },
    contabilidadSupervisor: {
        slug: 'CONTABILIDAD_SUPERVISOR',
        name: 'Contabilidad - Supervisor',
        description: 'Rol de supervisor de contabilidad',
    },
    contabilidadOperativo: {
        slug: 'CONTABILIDAD_OPERATIVO',
        name: 'Contabilidad - Operativo',
        description: 'Rol de operativo de contabilidad',
    },
    tesoreriaDirector: {
        slug: 'TESORERIA_DIRECTOR',
        name: 'Tesorería - Director',
        description: 'Rol de director de tesorería',
    },
    tesoreriaGerente: {
        slug: 'TESORERIA_GERENTE',
        name: 'Tesorería - Gerente',
        description: 'Rol de gerente de tesorería',
    },
    tesoreriaSupervisor: {
        slug: 'TESORERIA_SUPERVISOR',
        name: 'Tesorería - Supervisor',
        description: 'Rol de supervisor de tesorería',
    },
    tesoreriaOperativo: {
        slug: 'TESORERIA_OPERATIVO',
        name: 'Tesorería - Operativo',
        description: 'Rol de operativo de tesorería',
    },
    sistemasDirector: {
        slug: 'SISTEMAS_DIRECTOR',
        name: 'Sistemas - Director',
        description: 'Rol de director de sistemas',
    },
    sistemasGerente: {
        slug: 'SISTEMAS_GERENTE',
        name: 'Sistemas - Gerente',
        description: 'Rol de gerente de sistemas',
    },
    sistemasSupervisor: {
        slug: 'SISTEMAS_SUPERVISOR',
        name: 'Sistemas - Supervisor',
        description: 'Rol de supervisor de sistemas',
    },
    sistemasOperativo: {
        slug: 'SISTEMAS_OPERATIVO',
        name: 'Sistemas - Operativo',
        description: 'Rol de operativo de sistemas',
    },
}