export const PERMISSION_ACTIONS = {
    read: {
        name: 'read'
    },
    create: {
        name: 'create'
    },
    update: {
        name: 'update'
    },
    delete: {
        name: 'delete'
    },
    recover: {
        name: 'recover'
    },
    export: {
        name: 'export'
    },
    import: {
        name: 'import'
    },
    payrollSendToPendingAuthorize: {
        name: 'payrollPendingAuthorize'
    },
    payrollSendToAuthorized: {
        name: 'payrollAuthorized'
    },
    payrollSendToUnauthorized: {
        name: 'payrollUnauthorized'
    },
    bankLayoutSendToCreated: {
        name: 'bankLayoutSendToCreated'
    },
    bankLayoutSendToCompleted: {
        name: 'bankLayoutSendToCompleted'
    },
    bankLayoutSendToGenerated: {
        name: 'bankLayoutSendToGenerated'
    },
    invoiceCancel: {
        name: 'invoiceCancel'
    },
};

export const PERMISSION_SUBJECTS = {
    home: {
        name: 'home'
    },
    calendar: {
        name: 'calendar',
        pluralLabel: 'Calendario'
    },
    notifications: {
        name: 'notifications',
        pluralLabel: 'Notificaciones',
    },
    client: {
        name: 'client',
        pluralLabel: 'Clientes',
        config: {
            import: {
                entities: [
                    {
                        label: 'Información General',
                        enum: 'CLIENTES'
                    },
                    {
                        label: 'Empresas Asociadas',
                        enum: 'CLIENTES_EMPRESAS_PAGADORAS'
                    }
                ]
            }
        }
    },
    employee: {
        name: 'employee',
        pluralLabel: 'Empleados',
        config: {
            import: {
                entities: [
                    {
                        label: 'Información General',
                        enum: 'EMPLEADOS'
                    },
                    {
                        label: 'Cuentas Bancarias',
                        enum: 'EMPLEADOS_CUENTAS_BANCARIAS'
                    },
                    {
                        label: 'Empresas Asociadas',
                        enum: 'EMPLEADOS_EMPRESAS_PAGADORAS'
                    }
                ]
            }
        }
    },
    incidents: {
        name: 'incidents',
        pluralLabel: 'Incidencias',
        config: {
            import: {
                entities: [
                    {
                        label: 'Incidencias',
                        enum: 'INCIDENCIAS'
                    },
                ]
            }
        }
    },
    paymentStrategies: {
        name: 'paymentStrategies',
        pluralLabel: 'Estrategias de Pago',
    },
    payroll: {
        name: 'payroll',
        pluralLabel: 'Nóminas'
    },
    bankLayouts: {
        name: 'bankLayouts',
        pluralLabel: 'Layouts Bancarios',
    },
    payrollReceipts: {
        name: 'payrollReceipts',
        pluralLabel: 'Recibos Nóminas'
    },
    company: {
        name: 'company',
        pluralLabel: 'Empresas',
        config: {
            import: {
                entities: [
                    {
                        label: 'Información General',
                        enum: 'EMPRESAS'
                    },
                    {
                        label: 'Cuentas Bancarias',
                        enum: 'EMPRESAS_CUENTAS_BANCARIAS'
                    },
                    {
                        label: 'Registros Patronales',
                        enum: 'EMPRESAS_REGISTROS_PATRONALES'
                    },
                ]
            }
        }
    },
    bankConciliation: {
        name: 'bankConciliation',
        pluralLabel: 'Conciliaciones Bancarias'
    },
    bankTransaction: {
        name: 'bankTransaction',
        pluralLabel: 'Movimientos Bancarios'
    },
    promoter: {
        name: 'promoter',
        pluralLabel: 'Promotores'
    },
    payrollConcept: {
        name: 'payrollConcept',
        pluralLabel: 'Conceptos'
    },
    commissionExpense: {
        name: 'commissionExpense',
        pluralLabel: 'Gastos Comisión'
    },
    user: {
        name: 'user',
        pluralLabel: 'Usuarios'
    },
    emails: {
        name: 'emails',
        pluralLabel: 'Correos Electrónicos'
    },
    expedients: {
        name: 'expedients',
        pluralLabel: 'Expedientes Digitales',
    },
    imports: {
        name: 'imports',
        pluralLabel: 'Importaciones',
    },
    alerts: {
        name: 'alerts',
        pluralLabel: 'Alertas',
    },
    products: {
        name: 'products',
        pluralLabel: 'Productos',
    },
    intervalsPeriodicities: {
        name: 'intervalsPeriodicities',
        pluralLabel: 'Intervalos Periodicidades',
    },
    fiscalParameters: {
        name: 'fiscalParameters',
        pluralLabel: 'Parámetros Fiscales',
    },
    invoices: {
        name: 'invoices',
        pluralLabel: 'Facturas',
    },
    catalogs: {
        name: 'catalogs',
        pluralLabel: 'Catálogos',
    },
};


export const arrangeImportItemsBySubject = (props = {}) => {
    const {subject} = props;
    const response = [];
    if (subject) {
        const subjectData = PERMISSION_SUBJECTS[subject];
        if (subjectData?.config?.import?.entities) {
            const entities = subjectData.config.import.entities;
            if (entities && entities.length > 0) {
                entities.forEach(entity => {
                    response.push({
                        labelSubject: entity.label,
                        enumSubject: entity.enum,
                        pluralSubjectLabel: subjectData.pluralLabel
                    })
                });
            }
        }
    }

    return response;
}
